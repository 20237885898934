export type NavigationItem = {
  title: string
  icon: string
  link: string
  items: { title: string; link: string }[]
}

export const driverNavItems: NavigationItem[] = [
  {
    title: 'ドライバー日報',
    icon: 'mdi-account',
    link: '/daily_report/',
    items: []
  },
  {
    title: 'ドライバー予定',
    icon: 'mdi-account',
    link: '/driver_schedule/',
    items: []
  }
]
