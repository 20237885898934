


















































import { computed, createComponent } from '@vue/composition-api'
import { driverNavItems } from '@/pages/config'

export default createComponent({
  components: {},
  middleware: ['authenticated'],
  setup(_props, context) {
    if (context.root.$route) {
      const path = context.root.$route.path
      if (path.startsWith('/driver_schedule/')) {
        context.root.$router.push('/driver_schedule/')
      } else {
        context.root.$router.push('/daily_report/')
      }
    }
    const logout = async () => {
      await context.root.$auth.logout().catch(console.error)
      context.root.$router.push('/login/')
    }

    const userName = computed(() => {
      return context.root.$auth.user
        ? `${context.root.$auth.user.last_name}${context.root.$auth.user.first_name}`
        : ''
    })

    const userId = computed(() => {
      return context.root.$auth.user ? context.root.$auth.user.id : ''
    })

    return {
      userName,
      userId,
      logout,
      driverNavItems
    }
  }
})
