<template>
  <v-app dark>
    <v-alert :value="true" type="error">
      <h1 v-if="error.statusCode === 404" class="mb-3">
        {{ pageNotFound }}
      </h1>
      <h1 v-else class="mb-3">
        {{ otherError }}
      </h1>
      <NuxtLink to="/" class="error-home-button">
        ホーム
      </NuxtLink>
    </v-alert>
    <!-- <h1 v-if="error.statusCode === 404">
      {{ pageNotFound }}
    </h1>
    <h1 v-else>
      {{ otherError }}
    </h1>
    <NuxtLink to="/">
      Home page
    </NuxtLink> -->
  </v-app>
</template>

<script>
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 20px;
}

.v-alert__content > .nuxt-link-active {
  font-size: 20px;
  background-color: white;
  padding: 5px;
  border-radius: 0.2em;
  text-decoration: none;
  font-weight: 600;
}
</style>
